/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import './variables/variables';


.my-table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  vertical-align: middle;
}


ul li {
  list-style: none;}





@mixin hoverBottomOutlineEffect {
  > a {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      bottom: 0;
      height: 1px;
      margin: -2px 0;
      left: 0;
      background-color: $primary;
      transition: all 0.3s ease-in 0s;
    }
    &:hover {
      color: $primary !important;
    }
    &:hover:after {
      width: 100%;
    }
  }
}

.drop-down-container {
  display: none;
  width: 98% !important;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  top: /* calc(auto + 30px) */ auto;
  left: 10px !important;
  right: 10px !important ;
  //margin: 10px ;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 10px;
  z-index: 10000000;
  /* &::before {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 20px;
    content: "";
    display: block;
    z-index: 1;
  }
   &::after {
    position: absolute;
    top: -7px;
    left: inherit;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    content: "";
    display: block;
    z-index: 2;
    transition: all 200ms linear;
  }  */ 

  li {
    list-style: none !important;
    &:hover {
      background-color: transparent !important;
      color: black !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
}

.nav--bar {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none !important;
  position: relative;
  cursor: pointer;
  li {
    float: right;
    margin: 5px;
    padding: 10px;
    // position: relative !important;
    /* left: 0;
    right: 0; */
    top: auto;
    &:hover {
      background-color: $primary;
      border-radius: 10px;
      .desktop-item{
        color: white;
      }
      .drop-down-container {
        display: flex;
      }
    }
  }
}

.sub-list {
  color: $primary;
  border-bottom: 1px solid;
  padding: 5px;
  margin: 5px;
}

.nested-list {
  @include hoverBottomOutlineEffect;
   .icon-display {
    display: none;
  }
  &:hover {
    .icon-display {
      display: flex;
      margin-right: 4px;
      box-shadow:  0 !important;
    }
  } 
}



@media (max-width: 768px) { /* 768px is the width of a typical tablet screen */
  .customizer.open {
    width: 275px;
  }
}



//Chart
.chart-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.chart-data__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.chart-data__item-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.chart-data__item-value {
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}

//clock

.clock {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  color: #444;
  text-align: center;
}

.clock::after {
  background: #aaa;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
}

.hour_hand {
  position: absolute;
  width: 6px;
  height: 60px;
  background: #222;
  top: 30%;
  left: 49%;
  transform-origin: bottom;
}

.min_hand {
  position: absolute;
  width: 4px;
  height: 80px;
  background: #444;
  top: 22.5%;
  left: 49%;
  transform-origin: bottom;
}

.sec_hand {
  position: absolute;
  width: 2px;
  height: 118px;
  background: red;
  top: 10.5%;
  left: 50%;
  transform-origin: bottom;
}

.clock span {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

.twelve {
  top: 10px;
  left: 46%;
}

.one {
  top: 10%;
  right: 26%;
}

.eleven {
  top: 10%;
  left: 26%;
}

.two {
  top: 25%;
  right: 10%;
}

.three {
  right: 10px;
  top: 46%;
}

.four {
  right: 30px;
  top: 67%;
}

.five {
  right: 78px;
  top: 80%;
}

.six {
  bottom: 10px;
  left: 50%;
}

.seven {
  left: 80px;
  top: 82%;
}

.eight {
  left: 30px;
  top: 67%;
}

.nine {
  left: 10px;
  top: 46%;
}

.ten {
  top: 25%;
  left: 10%;
}


.leaflet-container {
  width: 100%;
  height: 100%;
}

.font-18{
  font-size: 18px;
}


.page-container {
  background-image: url('https://res.cloudinary.com/dunl944y2/image/upload/v1685061949/login_nwll3p.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
}

.equalHeightRow {
  display: flex;
  height: 120vh;
  overflow: scroll;
}

.dark-layout .leaflet-popup-content-wrapper, .leaflet-popup-tip{
  background: #283046
}

.dark-layout .drop-down-container{
  background-color: #283046
}

.mobile-width {
  @media (max-width: 1140px) { // Mobile screens based on Chrome standard breakpoints
   width: 100px
  }

  @media (min-width: 1141px) { // Other screen sizes
   width: 100%
  }
}